import { Button, Checkbox, PasswordInput, TextInput } from '@mantine/core'
import { showNotification } from '@mantine/notifications'
import { Cross1Icon } from '@modulz/radix-icons'
import React, { FC, useState } from 'react'
import { useCookies } from 'react-cookie'
import { Controller, FieldValues, SubmitHandler, useForm } from 'react-hook-form'

export interface ILoginFormData {
  username: string
  password: string
  rememberUsername: boolean
}

export interface ILoginCompProps {
  handleLogin: SubmitHandler<FieldValues>
}

const Login: FC<ILoginCompProps> = ({ handleLogin }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const [cookies] = useCookies(['rememberUsername'])
  const [rememberUsername] = useState(cookies['rememberUsername'] || '')
  const [rememberCheck] = useState(cookies['rememberUsername'] ? true : false)

  const handleNotifications = () => {
    if (errors.username) {
      showNotification({
        title: 'Username',
        message: 'username is required!',
        icon: <Cross1Icon />,
        color: 'red',
      })
    }
    if (errors.password) {
      showNotification({
        title: 'Password',
        message: 'password is required!',
        icon: <Cross1Icon />,
        color: 'red',
      })
    }
  }

  return (
    <form onSubmit={handleSubmit(handleLogin)}>
      <div className="mb-2">
        <Controller
          name="username"
          defaultValue={rememberUsername || ''}
          rules={{ required: true }}
          control={control}
          render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
            <TextInput
              label="이메일"
              description=""
              onChange={onChange}
              onBlur={onBlur}
              ref={ref}
              value={value}
              error={error?.type === 'required'}
              size="md"
              required
            />
          )}
        />
      </div>
      <div className="mb-2">
        <Controller
          name="password"
          defaultValue=""
          rules={{ required: true }}
          control={control}
          render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
            <PasswordInput
              label="비밀번호"
              description=""
              onChange={onChange}
              onBlur={onBlur}
              ref={ref}
              value={value}
              error={error?.type === 'required'}
              size="md"
              required
            />
          )}
        />
      </div>
      <div className="mb-2">
        <Controller
          name="rememberUsername"
          control={control}
          defaultValue={rememberCheck}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <Checkbox
              onChange={onChange}
              onBlur={onBlur}
              ref={ref}
              value={value}
              size="sm"
              label="이메일 기억하기"
            />
          )}
        />
      </div>
      <div className="mt-6">
        <Button type="submit" onClick={handleNotifications} size="md">
          로그인
        </Button>
      </div>
    </form>
  )
}

export default Login
