import Login from '@components/login'
import Footer from '@components/login-2/footer'
import Logo from '@components/login-2/logo'
import Text from '@components/login-2/text'
import { showNotification } from '@mantine/notifications'
import { Cross1Icon } from '@modulz/radix-icons'
import { RootState } from '@stores'
import { requestLoginThunk } from '@stores/auth'
import { useRouter } from 'next/router'
import { useCallback, useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { useDispatch, useSelector } from 'react-redux'

const Index = () => {
  const dispatch = useDispatch()
  const router = useRouter()
  const { idToken, loginFailNoti } = useSelector((state: RootState) => state.auth)
  const [cookies, setCookie, removeCookie] = useCookies(['rememberUsername'])

  useEffect(() => {
    const accessToken = localStorage.getItem('access_token')
    const refreshToken = localStorage.getItem('refresh_token')
    const refreshTokenExpiresAt = localStorage.getItem('refresh_token_expires_at')

    let isTokenAvailable: boolean = false
    if (
      accessToken !== null &&
      refreshToken !== null &&
      refreshTokenExpiresAt !== null &&
      parseInt(refreshTokenExpiresAt) > new Date().getTime()
    ) {
      isTokenAvailable = true
    }

    if (isTokenAvailable) {
      void router.push('/')
    }
  }, [idToken])

  useEffect(() => {
    if (loginFailNoti) {
      showNotification({
        title: 'invalid Username or Password',
        message: 'Please submit correct informations',
        color: 'red',
        icon: <Cross1Icon />,
      })
    }
  }, [loginFailNoti])

  const handleLogin = useCallback((data: any) => {
    const { username, password, rememberUsername } = data
    if (rememberUsername) {
      const expiresDay = new Date()
      expiresDay.setMonth(expiresDay.getMonth() + 1)
      setCookie('rememberUsername', username, {
        path: '/',
        expires: expiresDay,
      })
    }
    dispatch(requestLoginThunk(username, password))

    if (!rememberUsername) {
      removeCookie('rememberUsername')
    }
  }, [])

  return (
    <>
      <div className="w-full flex flex-row h-screen overflow-hidden">
        <div className="hidden lg:flex lg:flex-col w-1/2 text-white p-8 items-start justify-between relative bg-login-2">
          <Logo />
          <Text />
          <Footer />
        </div>
        <div className="w-full lg:w-1/2 bg-white p-8 lg:p-24 flex flex-col items-start justify-center">
          <p className="text-2xl font-bold text-blue-500 mb-4">Login</p>
          <Login handleLogin={handleLogin} />
        </div>
      </div>
    </>
  )
}

export default Index
